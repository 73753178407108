import React from "react";

function InstagramFeed() {
  return (
    <div className="social-box instagram-box">
      <div className="social-box-header">
        <span className="name">Instagram</span> <span>at</span>{" "}
        <a
          className="handle"
          href="https://www.instagram.com/ymcansw/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @ymcansw
        </a>
      </div>
    </div>
  );
}

export default InstagramFeed;
